import { Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../search/component/ViewProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import Loader from "../../../../library/common/Loader";
import { useNavigate } from "react-router-dom";
import { EDIT_PROFILE } from "../../../../constant/routeContant";
import CorporateProfileHeader from "./CorporateProfileHeader";
import defaultCoverPic from "../../../../assets/defaultCoverPic.svg";
import Buttons from "../../../../library/custom/button/Button";
import editBtnIcon from "../../../../assets/editBtnIcon.svg";
import ISwitch from "../../../../library/custom/switch/Switch";
import * as editProfileAction from "../../../../redux/actions/editProfileAction";

const CorporateProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const NavigateProfileFrom = () => {
    navigate(EDIT_PROFILE);
  };

  const searchUserData = useSelector(
    (state) => state?.userDetails?.data?.response
  );
  const isLoading = useSelector((state) => state?.userDetails?.isLoading);

  let skillsOShow = [];
  if (
    searchUserData?.profile.work_experience &&
    searchUserData?.profile.work_experience.skillsO &&
    searchUserData?.profile.work_experience.skillsO.length
  ) {
    let ski = [];
    for (
      let i = 0;
      i < searchUserData?.profile.work_experience.skillsO.length;
      i++
    ) {
      ski = [
        ...new Set([
          ...ski,
          ...searchUserData?.profile.work_experience.skillsO[i],
        ]),
      ];
    }
    if (ski.length > 10) {
      skillsOShow = ski.splice(0, 10);
    } else {
      skillsOShow = ski;
    }
  }
  let skillsPShow = [];
  if (
    searchUserData?.profile &&
    searchUserData?.profile?.work_experience &&
    searchUserData?.profile?.work_experience?.skillsP &&
    searchUserData?.profile?.work_experience?.skillsP?.length
  ) {
    let ski = [];
    for (
      let i = 0;
      i < searchUserData?.profile.work_experience.skillsP.length;
      i++
    ) {
      ski = [
        ...new Set([
          ...ski,
          ...searchUserData?.profile.work_experience.skillsP[i],
        ]),
      ];
    }
    if (ski.length > 5) {
      skillsPShow = ski.splice(0, 5);
    } else {
      skillsPShow = ski;
    }
  }
  const [uniqueIndustry, setuniqueIndustry] = useState([]);
  useEffect(() => {
    setuniqueIndustry([
      ...new Set(
        searchUserData?.profile?.work_experience?.industry?.map((i) => i)
      ),
    ]);
  }, [searchUserData]);

  const [checked, setChecked] = useState(
    searchUserData?.basicDetails?.currently_hiring
  );
  const handleChange = (event) => {
    setChecked(event.target.checked);
    // setIsStatusId(1);

    dispatch(
      editProfileAction.putEditProfile({
        basic_details: {
          currently_hiring: event.target.checked,
        },
      })
    );
  };
  return (
    <>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="overrideViewProfile">
            <Grid mt={2} className="coverPicGrid">
              <img
                className="coverpic"
                src={
                  searchUserData?.profile?.media?.coverPic === undefined
                    ? defaultCoverPic
                    : searchUserData?.profile?.media?.coverPic
                }
                alt=""
              />
            </Grid>
            <CorporateProfileHeader
              searchUserData={searchUserData}
              NavigateProfileFrom={NavigateProfileFrom}
            />
            <Grid
              display={{ lg: "none", md: "none", sm: "none" }}
              container
              item
              lg={2.3}
              xs={12}
              className="centerDiv"
              alignItems={"center"}
            >
              <Grid
                className="profileCompletionSlider"
                container
                item
                lg={12}
                justifyContent={"left"}
                mt={2}
              >
                <Grid item>
                  <Buttons
                    varientText={true}
                    label={"EDIT PROFILE"}
                    onClick={(e) => NavigateProfileFrom(e)}
                    icon={editBtnIcon}
                  />
                </Grid>

                <Grid container item justifyContent={"left"} gap={"10px"}>
                  <ISwitch
                    checked={checked}
                    onChange={handleChange}
                    name="status"
                  />
                  <p className="switchLabel">Currently Hiring</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="deatilsGrid" textAlign={"left"}>
              <Grid className="leftGrid" item lg={6.8} p={2}>
                <Grid className="aboutGrid" mt={2}>
                  <p className="font800 font20 sectionHeading">
                    About the Company
                  </p>
                  <p className="font400 font16 sectionDetails">
                    <span className="styled-about">
                      {searchUserData?.basicDetails?.culture
                        ? parse(searchUserData?.basicDetails?.culture)
                        : "No Summary Available"}
                    </span>
                  </p>
                </Grid>

                <Grid className="aboutGrid" mt={2}>
                  {searchUserData?.basicDetails.video_intro ? (
                    searchUserData?.basicDetails.video_intro
                      .substring(
                        searchUserData?.basicDetails.video_intro.length - 3
                      )
                      .toLowerCase() === "pdf" ? (
                      <embed
                        height={258}
                        scrolling={0}
                        className="fit-layout"
                        src={searchUserData?.basicDetails.video_intro}
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                        }}
                      ></embed>
                    ) : (
                      // <ReactPlayer url={basicDetails.video_intro} width='100%' height={270} controls/>
                      <video
                        id="vid2"
                        style={{
                          width: "100%",
                          height: "270px",
                          borderRadius: "15px",
                        }}
                        controls
                        key={searchUserData?.basicDetails.video_intro}
                        autoPlay="autoPlay"
                        muted
                        playsInline
                      >
                        <source
                          src={searchUserData?.basicDetails.video_intro}
                          type="video/mp4"
                        />
                        <source
                          src={searchUserData?.basicDetails.video_intro}
                          type="video/webm"
                        />
                      </video>
                    )
                  ) : (
                    <h1>No introduction</h1>
                  )}
                </Grid>
              </Grid>

              <Divider orientation="vertical" variant="middle" flexItem />

              <Grid className="rightGrid" item lg={5} p={2}>
                <Grid className="toolsGrid" mt={2}>
                  <p className="font800 font20 sectionHeading">
                    Tools, Technology and Framework
                  </p>
                  <Grid container mt={2} gap={"10px"}>
                    <p className="sub-text">
                      {searchUserData?.basicDetails?.current_road_map &&
                      searchUserData?.basicDetails?.current_road_map !== ""
                        ? searchUserData?.basicDetails?.current_road_map
                        : "Not Available"}
                    </p>
                  </Grid>
                </Grid>
                <Grid className="expGrid" mt={10}>
                  <p className="font800 font20 sectionHeading">Latest News</p>
                  <Grid container mt={2} gap={"10px"}>
                    <p className="sub-text">
                      {searchUserData?.basicDetails.future_road_map &&
                      searchUserData?.basicDetails.future_road_map !== ""
                        ? searchUserData?.basicDetails.future_road_map
                        : "Not Available"}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    </>
  );
};

export default CorporateProfile;
