export const uploadVideoType = {
  CLEAR : "CLEAR",
  POST_UPLOAD_VIDEO_PENDING: "[UPLOAD_VIDEO][REQUEST] Create Upload video",
  POST_UPLOAD_VIDEO_FAILURE:
    "[UPLOAD_VIDEO][RESPONSE]  Upload video Creation Failed",
  POST_UPLOAD_VIDEO_SUCCESS:
    "[UPLOAD_VIDEO][RESPONSE]  Upload video Created Successfull",
  UPLOAD_VIDEO_PAGE: "UploadVideoPage",

  POST_UPLOAD_CONTENT_PENDING:
    "[POST_UPLOAD_CONTENT_PENDING][REQUEST] Create Upload content",
  POST_UPLOAD_CONTENT_FAILURE:
    "[POST_UPLOAD_CONTENT_FAILURE][RESPONSE]  Upload content Creation Failed",
  POST_UPLOAD_CONTENT_SUCCESS:
    "[POST_UPLOAD_CONTENT_SUCCESS][RESPONSE]  Upload content Created Successfull",
  UPLOAD_CONTENT_PAGE: "UploadContentPage",
};
