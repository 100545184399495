import { Grid } from "@mui/material";
import React, { useState } from "react";
import TextButton from "../../../library/custom/button/Buttons";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { E_YEAR } from "../../../constant/constant";
import Calendar from "./Calendar";
import "./MentorshipCalendar.scss";
import AddSlotForm from "./AddSlotForm";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { addMinutesToTime, convertUTCToTimezone } from "../../../utils/helper";

const MentorshipCalendar = ({ editProfile, openModal, setOpenModal }) => {
  const [formVal, setformVal] = useState({
    monthInd: 0,
    month: undefined,
    year: undefined,
  });
  const [slotsArr, setslotsArr] = useState([]);
  let years = [];
  for (let i = E_YEAR; i <= E_YEAR + 1; i++) {
    years.push(i);
  }
  function saveSlots(params) {
    editProfile(params);
  }
  useEffect(() => {
    const monthIndex = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    }[formVal.month];
    setformVal({
      ...formVal,
      monthInd: monthIndex,
    });
  }, [formVal.month]);

  const userMentorshipData = useSelector(
    (state) => state?.userDetails?.data?.response?.profile?.mentorship ?? []
  );
  const timeZone = useSelector(
    (state) =>
      state?.userDetails?.data?.response?.profile?.personal_details?.timezone ??
      ""
  );
  const [availSlots, setavailSlots] = useState([]);
  useEffect(() => {
    let arr = [];
    userMentorshipData?.availableSlots?.forEach((i) => {
      let utcTime = timeZone ? convertUTCToTimezone(i, timeZone) : i;
      arr.push(utcTime);
    });
    setavailSlots(arr);
  }, [userMentorshipData]);

  useEffect(() => {
    let arr = [];
    availSlots?.forEach((i) => {
      let itemarr = i.split(" ");
      let dateArr = itemarr[0].split("-");
      const startHour = itemarr[1].split(":")[0];
      const endHour = itemarr[1].split(":")[1].split("-")[1];
      const startMin = itemarr[1].split(":")[1].split("-")[0];
      const endMin = itemarr[1].split(":")[2];
      arr.push({
        booked: false,
        time: itemarr[1],
        year: dateArr[0],
        month: dateArr[1].split(1),
        day: dateArr[2],
        title: "Available",
        start: new Date(
          dateArr[0],
          dateArr[1] - 1,
          dateArr[2],
          parseInt(startHour),
          parseInt(startMin)
        ),
        end: new Date(
          dateArr[0],
          dateArr[1] - 1,
          dateArr[2],
          parseInt(endHour),
          parseInt(endMin)
        ),
      });
    });
    setslotsArr(arr);
  }, [userMentorshipData, formVal]);
  const [isSelectedDate, setisSelectedDate] = useState({
    day: "",
    month: "",
    year: "",
    time:"",
    isSelectedDay: false,
  });

  return (
    <div className="overrideMentorshipCalendar">
      {openModal && (
        <AddSlotForm
          open={openModal}
          handleCancel={() => {
            setOpenModal(false);
            setisSelectedDate({
              day: "",
              month: "",
              year: "",
              isSelectedDay: false,
            });
          }}
          saveSlots={saveSlots}
          userDetailsData={availSlots}
          timeZoneLocal={timeZone}
          isSelectedDate={isSelectedDate}
          setisSelectedDate={setisSelectedDate}
        />
      )}
      <Grid container justifyContent={"space-between"}>
        <h1 className="pageName">Mentorship Calendar</h1>
        
      <Grid mt={4} >
        <TextButton
          className="textButton"
          label={"Add Mentorship Slots"}
          style={{
            color: "#778dff",
            fontWeight: 600,
          }}
          onClick={() => setOpenModal(true)}
        />
      </Grid>
      </Grid>

      <Grid
        className="fName"
        gap={1}
        item
        container
        lg={8}
        xs={12}
        sm={12}
        md={8}
      >
        <Grid container>
          <FormLabels label="Select Time Period" />
        </Grid>
      </Grid>
      <Grid mt={4}>
        <Calendar
          slots={slotsArr}
          month={formVal.monthInd}
          year={formVal.year}
          handleDateClick={(e) => {
            // console.log(e);
            const dateArr = e.dateStr.split("-");
            const dateArrWithTime = e.dateStr.split("T");
            console.log(dateArrWithTime)
            let t =dateArrWithTime[1]?.slice(0,5)
            console.log(t)
            setisSelectedDate({
              day: dateArrWithTime[0]?.split("-")[2],
              month: dateArrWithTime[0]?.split("-")[1],
              year: dateArrWithTime[0]?.split("-")[0],
              time:dateArrWithTime.length > 1 ?`${t}-${addMinutesToTime(t,60)}` : "",
              isSelectedDay: true,
            });
            setOpenModal(true);
            // setsessionFormValue({
            //   ...sessionFormValue,
            //   slot: `${startDate} ${e.startTime.slice(0, 5)}-${e.endTime.slice(
            //     0,
            //     5
            //   )}`,
            // });
          }}
        />
      </Grid>
    </div>
  );
};

export default MentorshipCalendar;
