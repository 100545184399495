import React, { useState } from "react";
import ManageMentorship from "../component/ManageMentorship";
import * as mentorshipAction from "../../../redux/actions/mentorshipAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Loader from "../../../library/common/Loader";
import JitsiMeet from "./MeetingContainer";

const ManageMentorshipContainer = () => {
  const dispatch = useDispatch();
  const [joinMeeting, setjoinMeeting] = useState(false);

  const userName = useSelector(
    (state) => state?.userDetails?.data?.response?.basicDetails?.first_name
  );
  const myMenteesData = useSelector((state) => state?.getMyMentees);
  const upcomingSessionsData = useSelector(
    (state) => state?.getMentorshipUpcomingSessions
  );
  const rejectReqData = useSelector(
    (state) => state?.postMentorshipRequestReject
  );
  const acceptReqData = useSelector(
    (state) => state?.postMentorshipRequestAccept
  );
  const acceptSessionData = useSelector(
    (state) => state?.postMentorshipSessionAccept
  );
  const rejectSessionData = useSelector(
    (state) => state?.postMentorshipSessionReject
  );
  const cancelSessionData = useSelector(
    (state) => state?.postMentorshipSessionCancel
  );
  useEffect(() => {
    getMyMentees();
    getUpcomgSessions();
  }, []);
  useEffect(() => {
    if (
      (!rejectReqData.isLoading && rejectReqData.status === "SUCCESS") ||
      (!acceptReqData.isLoading && acceptReqData.status === "SUCCESS") ||
      (!acceptSessionData.isLoading &&
        acceptSessionData.status === "SUCCESS") ||
      (!rejectSessionData.isLoading && rejectSessionData.status === "SUCCESS")||
      (!cancelSessionData.isLoading && cancelSessionData.status === "SUCCESS")
    ) {
      getMyMentees();
      getUpcomgSessions()
    }
  }, [rejectReqData, acceptReqData, acceptSessionData, rejectSessionData,cancelSessionData]);
  useEffect(() => {
   
    console.log(cancelSessionData)
  }, [cancelSessionData])
  
  function getMyMentees(params) {
    dispatch(mentorshipAction?.getMyMentees());
  }
  function getUpcomgSessions(params) {
    dispatch(mentorshipAction?.getMentorshipUpcomingSessions());
  }
  function onAccept(params) {
    dispatch(mentorshipAction?.postRequestMentorshipAccept({ uuid: params }));
  }
  function onReject(params) {
    dispatch(mentorshipAction?.postRequestMentorshipReject({ uuid: params }));
  }
  function onAcceptSession(params) {
    dispatch(mentorshipAction?.postMentorshipSessionAccept({ uuid: params }));
  }
  function onRejectSession(params) {
    dispatch(mentorshipAction?.postRequestMentorshipReject({ uuid: params }));
  }
  function onCancelSession(params) {
    dispatch(mentorshipAction?.postRequestMentorshipCancel({ uuid: params }));
  }
  function handleEndCall(params) {
    setjoinMeeting(false);
  }
  return (
    <div>
      {myMenteesData.isLoading && <Loader />}
      {rejectReqData.isLoading && <Loader />}
      {acceptReqData.isLoading && <Loader />}
      {joinMeeting ? (
        <JitsiMeet
          joinMeeting={joinMeeting}
          handleEndCall={() => handleEndCall()}
        />
      ) : (
        <ManageMentorship
          myMenteesData={myMenteesData}
          onAccept={onAccept}
          onReject={onReject}
          upcomingSessionsData={upcomingSessionsData?.data?.response ?? []}
          userName={userName}
          setjoinMeeting={setjoinMeeting}
          onAcceptSession={onAcceptSession}
          onRejectSession={onRejectSession}
          onCancelSession={onCancelSession}
        />
      )}
    </div>
  );
};

export default ManageMentorshipContainer;
