import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button as MUIBtn, Grid, IconButton } from "@mui/material";
import "./Calendar.scss";
import { useState } from "react";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import Buttons from "../../../library/custom/button/Button";
import { E_YEAR, TIEM_SLOTS, MONTHS } from "../../../constant/constant";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import TextButton from "../../../library/custom/button/Buttons";
import { useEffect } from "react";
import Loader from "../../../library/common/Loader";
import { convertToUTC, convertUTCToTimezone } from "../../../utils/helper";
import CloseIcon from "@mui/icons-material/Close";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AddSlotForm = ({
  open,
  saveSlots,
  handleCancel,
  userDetailsData,
  timeZoneLocal,
  isSelectedDate,
  setisSelectedDate,
}) => {
  const [slotFormValue, setSlotFormValue] = useState({
    month: isSelectedDate.isSelectedDay
      ? MONTHS[isSelectedDate.month - 1]
      : undefined,
    monthNum: isSelectedDate.isSelectedDay ? isSelectedDate.month : undefined,
    isMonth: false,
    year: isSelectedDate.isSelectedDay ? isSelectedDate.year : undefined,
    isYear: false,
    day: isSelectedDate.isSelectedDay ? isSelectedDate.day : undefined,
    isDay: false,
    time: isSelectedDate.isSelectedDay ? isSelectedDate.time : undefined,
    isTime: false,
    addedSlotsPayload: [],
  });
  const [checkError, setcheckError] = useState(true);

  function filterFutureDates(dates) {
    const now = new Date();

    return dates?.filter((dateRange) => {
      const [startDate, endDate] = dateRange.split(" ");
      const startDateTime = new Date(startDate);

      return startDateTime >= now;
    });
  }

  useEffect(() => {
    const futureDates = filterFutureDates(userDetailsData);
    let arr = [];
    futureDates?.forEach((i) => {
      let utcTime = timeZoneLocal ? convertUTCToTimezone(i, timeZoneLocal) : i;
      arr.push(utcTime);
    });
    setSlotFormValue({
      ...slotFormValue,
      addedSlotsPayload: futureDates ?? [],
    });
  }, [userDetailsData]);

  let years = [];
  for (let i = E_YEAR; i <= E_YEAR + 1; i++) {
    years.push(i);
  }
  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  function getUpcomingDays(year, month) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    const daysInMonth = getDaysInMonth(year, month);
    if (year === currentYear && month === currentMonth) {
      return Array.from(
        { length: daysInMonth - currentDay },
        (_, i) => currentDay + i
      );
    }
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  }

  useEffect(() => {
    const monthIndex = MONTHS.indexOf(slotFormValue.month) + 1;
    setdays(getUpcomingDays(parseInt(slotFormValue.year), monthIndex));
  }, [slotFormValue.month, slotFormValue.year]);

  const [days, setdays] = useState([]);

  function mapError(params) {
    let error = false;
    if (slotFormValue.day === "" || slotFormValue.day === undefined) {
      setIsNewDay(true);
      error = true;
    }
    if (slotFormValue.year === "" || slotFormValue.year === undefined) {
      setIsNewYear(true);
      error = true;
    }
    if (slotFormValue.month === "" || slotFormValue.month === undefined) {
      setIsNewMonth(true);
      error = true;
    }
    if (slotFormValue.time === "" || slotFormValue.time === undefined) {
      setIsNewTime(true);
      error = true;
    }

    const monthIndex = MONTHS.indexOf(slotFormValue.month) + 1;
    if (
      slotFormValue.addedSlotsPayload.includes(
        `${slotFormValue.year}-${monthIndex}-${slotFormValue.day} ${slotFormValue.time}`
      )
    ) {
      setIsSlot(true);
      error = true;
    }
    return error;
  }
  const [isNewMonth, setIsNewMonth] = useState(false);
  const [isNewYear, setIsNewYear] = useState(false);
  const [isNewDay, setIsNewDay] = useState(false);
  const [isNewTime, setIsNewTime] = useState(false);
  const [isSlot, setIsSlot] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  function handleAddMore(params) {
    const error = checkError ? mapError() : false;
    setshowLoader(true);
    setTimeout(() => {
      setshowLoader(false);
    }, 300);
    if (!error) {
      let arrPayload = slotFormValue.addedSlotsPayload;
      const monthIndex = MONTHS.indexOf(slotFormValue.month) + 1;
      if (!checkError) {
      } else if (checkError)
        arrPayload.push(
          `${slotFormValue.year}-${
            monthIndex > 9 ? monthIndex : `0${monthIndex}`
          }-${
            parseInt(slotFormValue.day) > 9
              ? slotFormValue.day
              : `0${slotFormValue.day}`
          } ${slotFormValue.time}`
        );
      setSlotFormValue({
        ...slotFormValue,
        month: undefined,
        year: undefined,
        day: undefined,
        time: undefined,
        // addedSlots: arr,
        addedSlotsPayload: arrPayload,
      });
      setcheckError(false)
      if (params) {
        handleOnClick();
      }
      setisSelectedDate({
        sday: "",
        month: "",
        year: "",
        time: "",
        isSelectedDay: false,
      });
    }
  }
  function getUpcomingMonths(year) {
    const currentYear = new Date().getFullYear();
    const currentMonthIndex = new Date().getMonth();
    if (parseInt(year) > currentYear) {
      setstartMonthList(MONTHS);
      setSlotFormValue({
        ...slotFormValue,
        month: undefined,
      });
      return MONTHS;
    }
    const upcomingMonths = [];
    for (let i = currentMonthIndex; i < MONTHS.length; i++) {
      upcomingMonths.push(MONTHS[i]);
    }
    setstartMonthList(upcomingMonths);
    return upcomingMonths;
  }
  const [startMonthList, setstartMonthList] = useState([]);
  useEffect(() => {
    getUpcomingMonths(slotFormValue.year);
  }, [slotFormValue.year]);

  function handleOnClick(params) {
    let arr = [];
    slotFormValue.addedSlotsPayload.forEach((i) => {
      let utcTime = timeZoneLocal ? convertToUTC(i, timeZoneLocal) : i;
      arr.push(utcTime);
    });
    if (isSelectedDate.isSelectedDay) {
      const date = `${slotFormValue.year}-${slotFormValue.monthNum}-${slotFormValue.day} ${slotFormValue.time}`;
      arr.push(timeZoneLocal ? convertToUTC(date, timeZoneLocal) : date);
    }
    saveSlots({
      mentorship: {
        availableSlots: arr,
      },
    });
  }
  function handleDelete(item) {
    let arr = slotFormValue.addedSlotsPayload.filter((i) => i !== item);
    setcheckError(false);
    setSlotFormValue({ ...slotFormValue, addedSlotsPayload: arr });
  }
  return (
    <div className="overirdeCalendar">
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        className="dialogBoxContainer"
      >
        <DialogContent className="Dioerrimg">
          <h1>Add Mentorship Slots</h1>
          {showLoader ? (
            <Loader />
          ) : (
            <>
              <Grid
                className="fName"
                gap={1}
                item
                container
                lg={12}
                xs={12}
                sm={12}
                md={6}
              >
                <Grid container>
                  <FormLabels label="Select Time Period" />
                </Grid>
                <Grid item lg={5.8} xs={12}>
                  <SelectMenu
                    disabledDefault={true}
                    label="Month"
                    id={"startM"}
                    value={slotFormValue.month}
                    name={"startM"}
                    onchangehandler={(e) => {
                      setSlotFormValue({
                        ...slotFormValue,
                        month: e.target.value,
                      });
                      setIsNewMonth(false);
                      setIsSlot(false);
                      setcheckError(true);
                    }}
                    listData={startMonthList}
                    countries={true}
                    isError={isNewMonth}
                    errorMsg="Please enter this field"
                  />
                </Grid>
                <Grid item lg={5.8} xs={12}>
                  <SelectMenu
                    disabledDefault={false}
                    label="Year"
                    id={"startY"}
                    value={slotFormValue.year}
                    name={"startY"}
                    onchangehandler={(e) => {
                      setSlotFormValue({
                        ...slotFormValue,
                        year: e.target.value,
                      });
                      setIsNewYear(false);
                      setIsSlot(false);
                      setcheckError(true);
                    }}
                    listData={years}
                    countries={true}
                    isError={isNewYear}
                    errorMsg="Please enter this field"
                  />
                </Grid>
              </Grid>
              <Grid
                className="fName"
                gap={1}
                item
                container
                lg={12}
                xs={12}
                sm={12}
                md={6}
                mt={2}
              >
                <Grid container>
                  <FormLabels label="Select Time Period" />
                </Grid>
                <Grid item lg={5.8} xs={12}>
                  <SelectMenu
                    disabledDefault={true}
                    label="Date"
                    id={"startM"}
                    value={slotFormValue.day}
                    name={"startM"}
                    onchangehandler={(e) => {
                      setSlotFormValue({
                        ...slotFormValue,
                        day: e.target.value,
                      });
                      setIsNewDay(false);
                      setIsSlot(false);
                      setcheckError(true);
                    }}
                    listData={days}
                    countries={true}
                    isError={isNewDay}
                    errorMsg="Please enter this field"
                  />
                </Grid>
                <Grid item lg={5.8} xs={12}>
                  <SelectMenu
                    disabledDefault={false}
                    label="Time"
                    id={"startY"}
                    value={slotFormValue.time}
                    name={"startY"}
                    onchangehandler={(e) => {
                      setSlotFormValue({
                        ...slotFormValue,
                        time: e.target.value,
                      });
                      setIsNewTime(false);
                      setIsSlot(false);
                      setcheckError(true);
                    }}
                    listData={TIEM_SLOTS}
                    countries={true}
                    isError={isNewTime}
                    errorMsg="Please enter this field"
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid container mt={4}>
            <Grid container gap={1}>
              {slotFormValue.addedSlotsPayload.map((i) => (
                <MUIBtn
                  className="slotBtn"
                  autoFocus
                  // onClick={exitHandle}
                  variant="contained"
                >
                  {i}

                  <IconButton
                    onClick={() => handleDelete(i)}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </MUIBtn>
              ))}
            </Grid>
          </Grid>

          <Grid container mt={4}>
            <Grid container>
              {isSlot && (
                <p style={{ color: "red", fontSize: "12px", margin: 0 }}>
                  Slot already added
                </p>
              )}
            </Grid>
            <TextButton
              className="textButton"
              label={"Add Slot"}
              onClick={() => {
                handleAddMore();
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid className="reqBtn">
            <Buttons
              onClick={() => handleAddMore(true)}
              varientContained={true}
              label={"Save"}
            />
          </Grid>

          <Buttons
            onClick={() => handleCancel()}
            varientLeaveContained={true}
            label={"Cancel"}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default AddSlotForm;
