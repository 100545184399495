import { Grid, FormControl } from "@mui/material";
import React, { useState } from "react";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../library/custom/textBox/InputField";

import { DIALCODES } from "../../../constant/countries";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { GENDER, OFFSETS } from "../../../constant/constant";
import Buttons from "../../../library/custom/button/Button";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import RichTextEditor from "../../../library/custom/richTextEditor/RichTextEditor";
import DatePickers from "../../../library/custom/datePicker/DatePicker";
import WorkAuthorizationAccordion from "./WorkAuthorizationAccordion";
import TextButton from "../../../library/custom/button/Buttons";
const BasicDetailsForm = ({
  basicDetails,
  setBasicDetails,
  Countries,
  personalDetails,
  setPersonalDetails,
  submitBasicDetails,
  unsavedChanges,
  setUnsavedChanges,
  cancelForm,
  handleAuthorizationFormChange,
  addNewAuthorizationForm,
}) => {
  const [isFirstName, setIsFirstName] = useState(false);
  const [isLastName, setIsLastName] = useState(false);
  const [isZipCode, setIsZipCode] = useState(false);
  const [isCountry, setIsCountry] = useState(false);
  const [isState, setIsState] = useState(false);
  const [isCity, setIsCity] = useState(false);
  const [isCitizenship, setisCitizenship] = useState(false);
  const [isTimeZone, setisTimeZone] = useState(false);
  const [isAbout, setIsAbout] = useState(false);
  const handleError = (exit=false) => {
    let error = false;
    if (
      basicDetails.first_name?.trim() === "" ||
      basicDetails.first_name === undefined ||
      basicDetails.first_name === null
    ) {
      setIsFirstName(true);
      error = true;
    }
    if (
      basicDetails.last_name?.trim() === "" ||
      basicDetails.last_name === undefined ||
      basicDetails.last_name === null
    ) {
      setIsLastName(true);
      error = true;
    }
    if (
      basicDetails.zipcode === undefined ||
      basicDetails.zipcode?.trim() === "" ||
      basicDetails.zipcode === null
    ) {
      setIsZipCode(true);
      error = true;
    }
    if (
      basicDetails.country_name?.trim() === "" ||
      basicDetails.country_name === undefined ||
      basicDetails.country_name === null
    ) {
      setIsCountry(true);
      error = true;
    }
    if (
      basicDetails.state?.trim() === "" ||
      basicDetails.state === undefined ||
      basicDetails.state === null
    ) {
      setIsState(true);
      error = true;
    }
    if (
      basicDetails.city?.trim() === "" ||
      basicDetails.city === undefined ||
      basicDetails.city === null
    ) {
      setIsCity(true);
      error = true;
    }
    if (
      basicDetails.citizenship?.trim() === "" ||
      basicDetails.citizenship === undefined ||
      basicDetails.citizenship === null
    ) {
      setisCitizenship(true);
      error = true;
    }
    // if (
    //   basicDetails.timezone?.trim() === "" ||
    //   basicDetails.timezone === undefined ||
    //   basicDetails.timezone === null
    // ) {
    //   setisTimeZone(true);
    //   error = true;
    // }
    if (
      basicDetails.aboutMe === undefined ||
      basicDetails.aboutMe === null
    ) {
      setIsAbout(true);
      error = true;
    }
    let updatedAuthArr = [...personalDetails];
    updatedAuthArr.forEach((data) => {
      if (
        data.countryList?.trim() === "" ||
        data.countryList === undefined ||
        data.countryList === null
      ) {
        data.isCountryList = true;
        error = true;
      }
      if (
        data.authType?.trim() === "" ||
        data.authType === undefined ||
        data.authType === null
      ) {
        data.isAuthType = true;
        error = true;
      }
      if (
        data?.authExp === "" ||
        data?.authExp === undefined ||
        data?.authExp === null
      ) {
        data.isAuthExp = true;
        error = true;
      }
      setPersonalDetails(updatedAuthArr);
    });
    if (!error) {
      submitBasicDetails(exit);
      // handleTabClick(2);
    }
  };
  return (
    <>
      <Grid className="fName" item container lg={12}>
        <Grid item lg={5.3} xs={12} sm={12} md={12}>
          <InputField
            type="text"
            label="First Name"
            placeholder="Enter here"
            value={basicDetails.first_name}
            onChange={(e) => {
              setBasicDetails({
                ...basicDetails,
                first_name: e.target.value,
              });
              setUnsavedChanges(true);
            }}
            isRequired={true}
            errorMsg={isFirstName ? "Please enter this required field" : ""}
            isError={isFirstName}
          />
        </Grid>
        <Grid item lg={5.3} xs={12}>
          <InputField
            type="text"
            label="Last Name"
            placeholder="Enter here"
            value={basicDetails.last_name}
            onChange={(e) => {
              setUnsavedChanges(true);
              setBasicDetails({
                ...basicDetails,
                last_name: e.target.value,
              });
            }}
            isRequired={true}
            isError={isLastName}
            errorMsg={isLastName ? "Please enter this required field" : ""}
          />
        </Grid>
      </Grid>

      <Grid textAlign={"left"} lg={12} mt={2}>
        <Grid className="label">Phone Number</Grid>

        <Grid className="fName" lg={12}>
          <Grid lg={1.5}>
            <SelectMenu
              disabledDefault={false}
              listData={DIALCODES}
              countries={true}
              value={basicDetails.dial_code}
              onchangehandler={(e) => {
                setUnsavedChanges(true);
                setBasicDetails({
                  ...basicDetails,
                  dial_code: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid lg={9} xs={9.9} sm={12}>
            <InputField
              fullWidth={true}
              type="text"
              placeholder={"Enter here"}
              value={basicDetails.phone}
              onChange={(e) => {
                setUnsavedChanges(true);
                setBasicDetails({
                  ...basicDetails,
                  phone: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={10.8} mt={2}>
        <InputField
          fullWidth={true}
          type="text"
          label="Recovery Email"
          placeholder="Enter here"
          value={basicDetails.recovery_email}
          onChange={(e) => {
            setUnsavedChanges(true);
            setBasicDetails({
              ...basicDetails,
              recovery_email: e.target.value,
            });
          }}
        />
      </Grid>
      <Grid mt={2} lg={10.8}>
        <InputField
          fullWidth={true}
          type="text"
          label="Zip Code"
          placeholder="Enter here"
          value={basicDetails.zipcode}
          onChange={(e) => {
            setUnsavedChanges(true);
            setBasicDetails({
              ...basicDetails,
              zipcode: e.target.value,
            });
          }}
          isRequired={true}
          isError={isZipCode}
          errorMsg={isZipCode ? "Please enter this required field" : ""}
        />
      </Grid>

      <Grid mt={2} lg={10.8}>
        <InputField
          fullWidth={true}
          type="text"
          label="Address"
          placeholder="Enter here"
          value={basicDetails.address_line}
          onChange={(e) => {
            setUnsavedChanges(true);
            setBasicDetails({
              ...basicDetails,
              address_line: e.target.value,
            });
          }}
        />
      </Grid>

      <Grid className="fName" item container lg={12} mt={2}>
        <Grid item lg={5.3} xs={12} sm={12} md={12}>
          <FormControl fullWidth className="selectLabel">
            <FormLabels isRequired={true} label="Country/Region" />
            <SelectMenu
              disabledDefault={false}
              listData={Countries}
              countries={true}
              value={basicDetails.country_name}
              onchangehandler={(e) =>
                setBasicDetails({
                  ...basicDetails,
                  country_name: e.target.value,
                })
              }
              isError={isCountry}
              errorMsg={isCountry ? "Please enter this required field" : ""}
            />
          </FormControl>
        </Grid>
        <Grid item lg={5.3} xs={12}>
          <InputField
            fullWidth={true}
            type="text"
            label="State"
            placeholder="Enter here"
            value={basicDetails.state}
            onChange={(e) => {
              setUnsavedChanges(true);
              setBasicDetails({
                ...basicDetails,
                state: e.target.value,
              });
            }}
            isRequired={true}
            isError={isState}
            errorMsg={isState ? "Please enter this required field" : ""}
          />
        </Grid>
      </Grid>

      <Grid className="fName" item container lg={12} mt={2}>
        <Grid item lg={5.3} xs={12} sm={12} md={12}>
          <InputField
            fullWidth={true}
            type="text"
            label="City"
            placeholder="City"
            value={basicDetails.city}
            onChange={(e) => {
              setUnsavedChanges(true);
              setBasicDetails({
                ...basicDetails,
                city: e.target.value,
              });
            }}
            isRequired={true}
            isError={isCity}
            errorMsg={isCity ? "Please enter this required field" : ""}
          />
        </Grid>
        <Grid item lg={5.3} xs={12}>
          <InputField
            fullWidth={true}
            type="text"
            label="Country of Citizenship "
            placeholder="Country of Citizenship "
            value={basicDetails.citizenship}
            onChange={(e) => {
              setUnsavedChanges(true);
              setBasicDetails({
                ...basicDetails,
                citizenship: e.target.value,
              });
            }}
            isRequired={true}
            isError={isCitizenship}
            errorMsg={isCitizenship ? "Please enter this required field" : ""}
          />
        </Grid>
      </Grid>
      <Grid className="fName" item container lg={12} mt={2}>
        <Grid item lg={5.3} xs={12} sm={12} md={12}>
          <FormLabels label="Time Zone" />
          <SelectMenu
              disabledDefault={false}
              listData={OFFSETS}
              countries={true}
              value={basicDetails.timezone}
              onchangehandler={(e) =>
                setBasicDetails({
                  ...basicDetails,
                  timezone: e.target.value,
                })
              }
              isError={isTimeZone}
              errorMsg={isTimeZone ? "Please enter this required field" : ""}
            />
        </Grid>
      </Grid>
      <Grid className="fName" item container lg={12} mt={2}>
        <Grid item lg={5.3} xs={12} sm={12} md={12}>
          <FormLabels label="Date of Birth" />
          <DatePickers
            value={basicDetails.dob}
            // value={"10/15/2018"}
            onChangeHandler={(e) => {
              setBasicDetails({ ...basicDetails, dob: e });
              // setisstartDate(false);
            }}
            // isError={isstartDate}
            // errorMsg={isstartDate ? isstartDateErrMsg : ""}
          />
        </Grid>
        <Grid item lg={5.3} xs={12} sm={12} md={12}>
          <FormControl fullWidth className="selectLabel">
            <FormLabels label="Gender" />
            <SelectMenu
              disabledDefault={false}
              listData={GENDER}
              // isKeyValue={true}
              countries={true}
              value={basicDetails.gender}
              onchangehandler={(e) => {
                setUnsavedChanges(true);
                setBasicDetails({
                  ...basicDetails,
                  gender: e.target.value,
                });
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid mt={2} className="selectLabel" lg={11} md={12} xs={12}>
        <FormControl fullWidth textAlign="left">
          <FormLabels label="About Me" isRequired={true} />{" "}
          <RichTextEditor
           toolbarOptions = {[
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
          ]}
            value={basicDetails?.aboutMe}
            placeholder={"Enter here"}
            onChange={(e) => {
              setUnsavedChanges(true);
              setBasicDetails({
                ...basicDetails,
                aboutMe: e,
              });
            }}
            // editorHtml={editorHtml}
            // setEditorHtml={setEditorHtml}
          />
        </FormControl>
      </Grid>

      <Grid textAlign={"left"} item lg={11} gap={"10px"} xs={12}>
        <Grid container item lg={6} mt={1.5}>
          <FormLabels
            label={"Are you Currently a Student?"}
            isRequired={true}
          />
        </Grid>

        <Grid container>
          <Grid item lg={2}>
            <RadioButton
              value={"No"}
              id={"is_student"}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  is_student: "NO",
                });
                setUnsavedChanges(false);
              }}
              label="No"
              checked={basicDetails.is_student === "NO"}
            />
          </Grid>
          <Grid item lg={2}>
            <RadioButton
              value={"Yes"}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  is_student: "Yes",
                });
                setUnsavedChanges(false);
              }}
              label="Yes"
              id={"tillDate"}
              checked={basicDetails?.is_student === "Yes"}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid textAlign={"left"} item lg={11} gap={"10px"} xs={12}>
        <Grid container item lg={11} mt={1.5}>
          <FormLabels
            label={"Are you authorized to work in any other countries?"}
            isRequired={true}
          />
        </Grid>

        <Grid container>
          <Grid item lg={2}>
            <RadioButton
              value={"No"}
              id={"tillDate"}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  other_country_authorization: "NO",
                });
                setUnsavedChanges(false);
              }}
              label="No"
              checked={basicDetails.other_country_authorization === "NO"}
            />
          </Grid>
          <Grid item lg={2}>
            <RadioButton
              value={"Yes"}
              onChange={(e) => {
                setBasicDetails({
                  ...basicDetails,
                  other_country_authorization: "YES",
                });
                setUnsavedChanges(false);
              }}
              label="Yes"
              id={"tillDate"}
              checked={basicDetails.other_country_authorization === "YES"}
            />
          </Grid>
        </Grid>
      </Grid>

      {basicDetails.other_country_authorization === "YES" &&
        personalDetails?.map((i, j) => (
          <WorkAuthorizationAccordion
            singleAuthorization={i}
            handleAuthorizationFormChange={handleAuthorizationFormChange}
            personalDetails={personalDetails}
            setPersonalDetails={setPersonalDetails}
            index={j}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            cancelForm={cancelForm}
          />
        ))}

      {basicDetails.other_country_authorization === "YES" && (
        <Grid container justifyContent={"left"}>
          <TextButton
            className="textButton"
            label={"Add Another"}
            onClick={() => addNewAuthorizationForm()}
          />
        </Grid>
      )}
      <Grid className="editProfileBtn" gap={1}>
        <Grid className="saveBtn" >
          <Grid>
            <Buttons
              varientContained={true}
              label="Save"
              onClick={() => {
                handleError(true);
              }}
            />
          </Grid>
        </Grid>
        <Grid className="saveBtn">
          <Grid>
            <Buttons
              varientContained={true}
              label="Save and Continue"
              onClick={() => {
                handleError();
              }}
            />
          </Grid>
        </Grid>
        <Grid className="cancelBtn">
          <Grid>
            <Buttons
              varientContained={true}
              onClick={cancelForm}
              label="Cancel"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BasicDetailsForm;
