import React, { useEffect, useState } from "react";
import "./SessionUserCard.scss";
import { Grid } from "@mui/material";
import defaultLoginImage from "../../../assets/defaultLoginImage.svg";
import Buttons from "../../../library/custom/button/Button";
import { convertUTCToTimezone } from "../../../utils/helper";
const SessionUserCard = ({
  name = "",
  designation = "",
  date = "",
  time = "",
  onCancel,
  joinMeeting,
  profilePic = defaultLoginImage,
  endTime = "",
  timeZone,
}) => {
  
  const [localTime, setlocalTime] = useState("");
  useEffect(() => {
    setlocalTime(
      timeZone
        ? convertUTCToTimezone(`${date} ${time}-${endTime}`, timeZone)
        : `${date} ${time}-${endTime}`
    );
  }, [time]);

  return (
    <Grid className="overrideSessionCArd" p={2}>
      <Grid container justifyContent={"center"}>
        <img
          src={profilePic}
          alt=""
          height={"60px"}
          width={"60px"}
          className="profilePic"
        />
      </Grid>
      <Grid onClick={() => joinMeeting()} container justifyContent={"center"}>
        <p className="name font600 font12">{name}</p>
      </Grid>
      <Grid container justifyContent={"center"}>
        <p className="designation font400 font10">{designation}</p>
      </Grid>
      <Grid container justifyContent={"center"}>
        <p className="font400 font12 dateTime">Date: {localTime.slice(0,10)}</p>
      </Grid>
      <Grid container justifyContent={"center"}>
        <p className="font400 font12 dateTime">Time: {localTime.slice(11,16)}</p>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Buttons
          onClick={onCancel}
          varientLeaveContained={true}
          label={"Cancel"}
        />
      </Grid>
    </Grid>
  );
};

export default SessionUserCard;
