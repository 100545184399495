import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import TopCommunity from "../../../assets/topCommunityIcon.svg";
import "./NewsCard.scss";

export default function NewsCard({ alt, image, label1, label2 }) {
  return (
    <div className="newsCardContainer">
      <div className="imgBox">
        <img src={image} alt={alt} />
      </div>
      <div className="contentBox">
        <div className="simpleText">{label1}</div>
        <div className="heding">{label2}</div>
        <div className="seperators"></div>
      </div>
    </div>
  );
}
