import React, { useState } from "react";
import "./Accordion.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// import p from "@mui/material/p";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Accordions from "../../../library/custom/accordion/Accordion";
import { useEffect } from "react";
import Delete from "../../../assets/delete.svg";
import EditBtn from "../../../assets/EditBtn.svg";
import ArrowUp from "../../../assets/arrow_up.svg";
import ArrowDown from "../../../assets/arrow_down.svg";
import EducationForm from "./EducationForm";
import CertificateForm from "./CertificateForm";
import { Grid } from "@mui/material";

function CertificateAccordian({
  summary,
  itemKey,
  skip,
  catError,
  singleCertificate,
  handleCertificateFormChange,
  certificateArr,
  setCertificateArr,
  index,
  setUnsavedChanges,
  onChnageFileupload,
  imageurl,
  id,
  onChangeDelete,
}) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = React.useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, []);

  function deleteForm() {
    const newArr = certificateArr.filter((i) => i.id != singleCertificate.id);
    setCertificateArr(newArr);
  }
  function moveFormUp() {
    let newArr = [...certificateArr];

    let elementIndex = index;
    if (elementIndex === 0) return;

    let newIndex = index - 1;

    let element = newArr.splice(elementIndex, 1)[0];

    newArr.splice(newIndex, 0, element);
    setCertificateArr(newArr);
    setExpanded(false);
  }

  function moveFormDown() {
    let newArr = [...certificateArr];

    let elementIndex = index;
    if (elementIndex >= newArr.length - 1) return;

    let newIndex = index + 1;

    let element = newArr.splice(elementIndex, 1)[0];

    newArr.splice(newIndex, 0, element);
    setCertificateArr(newArr);
    setExpanded(false);
  }
  const maxIndex = certificateArr.length - 1;
  return (
    <div>
      <Accordion
        className={[
          "accordion_body",
          skip?.IsCategory && "disable_accordian",
        ].join(" ")}
        expanded={expanded}
        onChange={() => (skip?.IsCategory ? "" : handleChange())}
      >
        <AccordionSummary
          expandIcon={<img src={EditBtn} alt="" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="parentAccordion"
          style={{ flexBasis: "90%" }}
        >
          <div className="accordian_header_content">
            <Grid textAlign={"left"} item xs={6} md={9} sm={8}>
              <p>{singleCertificate.name}</p>
            </Grid>
            <div className="accordian_header_content_child">
              {index > 0 && (
                <button onClick={(e) => moveFormUp()}>
                  {" "}
                  <img src={ArrowUp} alt="" />
                </button>
              )}
              {index < maxIndex && (
                <button onClick={(e) => moveFormDown()}>
                  {" "}
                  <img src={ArrowDown} alt="" />
                </button>
              )}
              <button onClick={(e) => deleteForm()}>
                <img src={Delete} alt="" />
              </button>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails
          //    key={data.key}
          className="childAccordion"
        >
          <CertificateForm
            handleCertificateFormChange={handleCertificateFormChange}
            singleCertificate={singleCertificate}
            setUnsavedChanges={setUnsavedChanges}
            onChnageFileupload={onChnageFileupload}
            imageurl={imageurl}
            id={id}
            onChangeDelete={onChangeDelete}
          />
        </AccordionDetails>
        {/* );
          })} */}
      </Accordion>
      {catError && (
        <p className="error mb-1">Please enter this required field.</p>
      )}
    </div>
  );
}

export default React.memo(CertificateAccordian);
