import { Grid } from "@mui/material";
import React from "react";

import defaultLoginImage from "../../../assets/defaultLoginImage.svg";
import locationIcon from "../../../assets/locationIcon.svg";
import "./SearchedUserCard.scss";
import Buttons from "../button/Button";
const MentorUserCard = ({
  onClick,
  name,
  designation,
  companyName,
  state,
  city,
  country,
  skills,
  profilePic,
  label = "Request Mentorship",
  showSkills = true,
  total_experience,
  id,
  location,
}) => {
  return (
    <Grid className="cardBody" p={2}>
      <Grid container>
        <img
          src={profilePic ?? defaultLoginImage}
          alt=""
          height={"48px"}
          width={"48px"}
          className="profilePic"
        />

        <Grid item textAlign={"left"} ml={1} lg={9}>
          <p className="name">{name}</p>
          <p className="designation">{`${designation ?? ""} ${
            designation && companyName ? "at" : ""
          } ${companyName ?? ""}`}</p>
        </Grid>
      </Grid>

      <Grid
        // className="pointer"
        container
        justifyContent={"space-between"}
        mt={1}
      >
        <Grid item container lg={10} mt={1} alignItems={"center"}>
          <Grid
            item
            lg={1}
            // onClick={() => onLoactionClick()}
          >
            {/* <p>{<LocationOnIcon className="icon" />}</p> */}
            <img className="locationIcon" src={locationIcon} alt="" />
          </Grid>
          <Grid item lg={10} pb={"5px"}>
            <p className="adress">
              {location
                ? location
                : `${city}${city && ", "}${state}${state && ", "}${country}`}
            </p>

            {/* <p className="adress">{city !== null && city !== undefined && `${city}, `}</p>
              <p className="adress">
                {state !== null && state !== undefined && `${state}, `}{" "}
                <span className="country">
                  {country !== undefined && country !== null &&
                    (country === "United States of America" ? "USA" : country)}
                </span>
              </p> */}
          </Grid>
        </Grid>
        {total_experience && (
          <Grid item container lg={10}>
            <Grid item lg={10}>
              <p className="adress">Experience: {total_experience} years</p>
            </Grid>
          </Grid>
        )}
        {showSkills && (
          <Grid container mt={1}>
            <p className="adress">{`Skills: ${skills?.slice(0, 30)}${
              skills?.length > 30 ? "..." : ""
            }`}</p>
          </Grid>
        )}
        <Grid mt={1} container lg={12}>
          <Buttons
            onClick={() => onClick()}
            varientContained={true}
            label={label}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MentorUserCard;
